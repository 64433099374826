/* eslint-disable camelcase */
import { PrimaryButton, icons } from '@atfm/atfm-material-ui';
import { CircularProgress } from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatedToTimeStamp, timestampToDate, validateTime } from '@atfm/utils';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useInterval } from '../../utils/IntervalUtil';
import MultiCTAHandle from '../../components/MultiCTAHandle/MultiCTAHandle';
import InsertNewConfig from '../../components/SectoConfig/InsertNewConfig';
import SectoConfigComponent from '../../components/SectoConfig/SectoConfig';
import {
  SectorPlanEditErrorMessages,
  SectorRequestTimeWindow,
  SectorValidationMessages,
  SnackBarMessages,
  SourceNames,
  TimerIntervals,
} from '../../constants';
import updateNMPlan from '../../store/nm/actions';
import selectNmLoading from '../../store/nm/selectors';
import {
  changedCurrentPlan,
  clearConfigSelected,
  clearSectorEditError,
  configSelected,
  fetchConfigsData,
  fetchPossibleConfs,
  sectorEditError,
  updateSectorsConf,
} from '../../store/sectors/actions';
import {
  selectConfigsData,
  selectIsSavingConfig,
  selectPossibleConfs,
  selectSelectedCTA,
  selectSelectedConfig,
} from '../../store/sectors/selectors';
import { selectTimeData } from '../../store/time/selectors';
import AlertContext, { AlertTypes } from '../Context/AlertContext';
import './SectoConfigList.css';
import { interceptors } from '../../store/api/services';
import { fetchTimelineHotspotsData } from '../../store/hotspots/actions';
import { selectShowCompHotspots } from '../../store/featureFlags/selectors';
import { selectSelectedHotspot } from '../../store/hotspots/selectors';
import { selectUserSecto } from '../../store/user/selectors';
import { selectActiveWhatif } from '../../store/whatif/selectors';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const MINIMUM_INSERT_TIME_MILLISECONDS = 30 * 60 * 1000;

const calcStartTimesList = confs =>
  confs.reduce((startTimesList, value) => {
    const fromStartOfDay = moment.utc(value.from).hour(0).minute(0).valueOf();
    if (!startTimesList.includes(fromStartOfDay)) {
      return startTimesList.concat(fromStartOfDay);
    }
    return startTimesList;
  }, []);

const createSectorEntry = (index, conf, time, selectedConfig) => {
  const isCurrentTime = !(conf.from > time || conf.to < time);
  const isSelected = selectedConfig
    ? conf.conf_name === selectedConfig.localData.conf_name &&
      (conf.from === selectedConfig.localData.from ||
       conf.to === selectedConfig.localData.to)
    : false;

  return {
    id: `${index}_${time}`,
    isValid: true,
    validFrom: true,
    validFromMessage: '',
    validTo: true,
    validToMessage: '',
    isEditing: false,
    isHovering: false,
    isPastConf: !(conf.from > time || conf.to > time),
    isCurrent: isCurrentTime,
    isClicked: isSelected, // Set isClicked based on selectedConfig or current config
    localData: { conf_name: conf.conf_name, from: conf.from, to: conf.to },
    currentData: { conf_name: conf.conf_name, from: conf.from, to: conf.to },
  };
};

const initialState = (time, selectedCTA, selectedConfig) => {
  const initialTime = moment.utc(time).hour(0).minute(0).second(0)
    .millisecond(0)
    .valueOf();
  const confsTotal = selectedCTA && selectedCTA.confs ? selectedCTA.confs : [];
  const confs = confsTotal.filter(conf => conf.from >= initialTime);
  const startTimesList = calcStartTimesList(confs);

  const lists = startTimesList.map((startTime, index) => ({
    id: `${index}_${startTime}`,
    startTime,
    confs: confs
      .filter(conf => moment.utc(conf.from).hour(0).minute(0).valueOf() === startTime)
      .map((conf, confIndex) => createSectorEntry(confIndex, conf, time, selectedConfig)),
  }));

  // Check if any configuration is clicked
  const anyClicked = lists.some(list => list.confs.some(conf => conf.isClicked));

  // If no configuration is clicked, set the current one
  const updatedLists = lists.map(list => ({
    ...list,
    confs: list.confs.map(conf => ({
      ...conf,
      isClicked: !anyClicked && conf.isCurrent ? true : conf.isClicked,
    })),
  }));

  return {
    originalSecto: confs.map(conf => ({ ...conf })),
    lists: updatedLists,
  };
};

const getConfFromListsOfConfs = (lists, conditionFunction) => {
  const listFound = lists.find(list => list.confs.some(conditionFunction));
  if (listFound) {
    return listFound.confs.find(conditionFunction);
  }
  return undefined;
};

const SectoConfigListComponent = () => {
  const alertContext = useContext(AlertContext);
  const dispatch = useDispatch();

  const sectoPlan = useSelector(selectConfigsData);
  const possibleConfs = useSelector(selectPossibleConfs);
  const isSavingConfigs = useSelector(selectIsSavingConfig);
  const currentTime = useSelector(selectTimeData);
  const selectedCTA = useSelector(selectSelectedCTA);
  const selectedConfig = useSelector(selectSelectedConfig);
  const isLoadingNm = useSelector(selectNmLoading);
  const showComplexityHotspot = useSelector(selectShowCompHotspots);
  const selectedHotspot = useSelector(selectSelectedHotspot);
  const userSecto = useSelector(selectUserSecto);
  const activeWhatif = useSelector(selectActiveWhatif);

  const [lists, setLists] = useState(() => initialState(currentTime, selectedCTA, selectedConfig).lists);

  const [originalSecto, setOriginalSecto] = useState(
    () => initialState(currentTime, selectedCTA, selectedConfig).originalSecto,
  );

  const [isEditing, setIsEditing] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    day: undefined,
    delta: undefined,
  });
  const [hotspotsLoading, setHotspotLoading] = useState(false);

  const getTokenData = () => {
    const { sources } = interceptors.kc.tokenParsed;
    return sources.aim;
  };

  const localSelectedData = (confSelected) => {
    const { currentData } = confSelected;
    const { conf_name: confName } = currentData;
    return {
      localData: {
        ...confSelected.localData,
        confName: confSelected.localData.conf_name,
      },
      selectedData: {
        ...currentData,
        confName,
        to: currentData.to > currentData.from ? currentData.to : currentData.to + DAY_IN_MILLISECONDS,
      },
    };
  };

  const getActiveConf = (time, list) => {
    const checkIsActive = conf => !(conf.currentData.from > time || conf.currentData.to < time);

    const confSelected = getConfFromListsOfConfs(list, checkIsActive);

    return confSelected && localSelectedData(confSelected);
  };

  const reduceListsOfConfs = (items, field) =>
    items.reduce((finalPlan, list) => finalPlan.concat(list.confs.map(entry => ({ ...entry[field] }))), []);

  const [activeConf, setActiveConf] = useState(() => getActiveConf(currentTime, lists));

  const getProfileNames = data => data.map(cta => cta.name).toString();

  const getSectoPlanUpdated = (profileName) => {
    const from = currentTime - SectorRequestTimeWindow.DELTA_FROM;
    const to = currentTime + SectorRequestTimeWindow.DELTA_TO;
    dispatch(fetchConfigsData(profileName, from, to));
  };

  const getHotspots = (
    startTime,
    stopTime,
  ) => {
    if (userSecto && startTime && stopTime) {
      setHotspotLoading(true);
      dispatch(
        fetchTimelineHotspotsData(
          currentTime,
          userSecto,
          startTime,
          stopTime,
          selectedHotspot,
          activeWhatif?.sessionId,
          true,
          true,
          showComplexityHotspot,
        ),
      ).then(() => setHotspotLoading(false));
    }
  };

  const saveChanges = (list) => {
    dispatch(
      updateSectorsConf(
        selectedCTA.name,
        {
          name: selectedCTA.name,
          confs: list.confs.map(entry => ({ ...entry.localData })),
        },
        list.startTime,
      ),
    ).then(() => {
      setIsEditing(false);
      getSectoPlanUpdated(getProfileNames(sectoPlan));
      const from = currentTime - SectorRequestTimeWindow.DELTA_FROM;
      const to = currentTime + SectorRequestTimeWindow.DELTA_TO;
      getHotspots(from, to);

      alertContext.setErrorMessageSnackBar(SnackBarMessages.SECTO_PLAN_UPDATE);
      alertContext.setSnackBarSeverity(AlertTypes.SUCCESS);
      alertContext.setShowSnackBar(true);
    });
  };

  const cancelChanges = () => {
    const initial = initialState(currentTime, selectedCTA, selectedConfig);
    setLists(initial.lists);
    setOriginalSecto(initial.originalSecto);
    setIsEditing(false);
    dispatch(changedCurrentPlan(reduceListsOfConfs(initial.lists, 'currentData')));
  };

  const changeClickStatus = (listId, entryId) => {
    setLists(prevLists =>
      prevLists.map(list => ({
        ...list,
        confs: list.confs.map(entry =>
          (list.id === listId && entry.id === entryId ? { ...entry, isClicked: true } : { ...entry, isClicked: false }),
        ),
      })),
    );
  };

  const changeEditStatus = (listId, entryId, status) => {
    if (status) {
      setIsEditing(true);
      setLists(prevLists =>
        prevLists.map(list =>
          (list.id === listId
            ? {
              ...list,
              confs: list.confs.map(entry => (entry.id === entryId ? { ...entry, isEditing: status } : entry)),
            }
            : list),
        ),
      );
    } else {
      setLists(prevLists =>
        prevLists.map(list =>
          (list.id === listId
            ? {
              ...list,
              confs: list.confs.map(entry =>
                (entry.id === entryId
                  ? {
                    ...entry,
                    isValid: true,
                    validFrom: true,
                    validFromMessage: '',
                    validTo: true,
                    validToMessage: '',
                    isEditing: status,
                    currentData: { ...entry.localData },
                  }
                  : entry),
              ),
            }
            : list),
        ),
      );
      dispatch(changedCurrentPlan(reduceListsOfConfs(lists, 'currentData')));
    }
  };

  const validateFromData = (index, value, processedValue, list) => {
    if (index === 0 && value !== '0000') {
      return {
        validFrom: false,
        validFromMessage: SectorValidationMessages.FROM_VALUE_START_ERROR,
      };
    }
    if (index !== list.length - 1 && processedValue >= list[index].localData.to) {
      return {
        validFrom: false,
        validFromMessage: SectorValidationMessages.FROM_BIGGER_TO_ERROR,
      };
    }
    if (index !== 0 && processedValue <= list[index - 1].localData.from) {
      return {
        validFrom: false,
        validFromMessage: SectorValidationMessages.FROM_VALUE_ERROR,
      };
    }
    return { validFrom: true, validFromMessage: '' };
  };

  const changeFrom = (listId, entryId, value) => {
    setLists(prevLists =>
      prevLists.map((list) => {
        if (list.id === listId) {
          const updatedConfs = list.confs.map((entry) => {
            if (entry.id === entryId) {
              let { validTime: validFrom, validTimeMessage: validFromMessage } = validateTime(value);
              let finalValue = value;
              if (validFrom) {
                const index = list.confs.findIndex(e => e.id === entryId);
                const processedValue = formatedToTimeStamp(value, list.startTime, value.includes(':'));
                ({ validFrom, validFromMessage } = validateFromData(index, value, processedValue, list.confs));
                if (validFrom) finalValue = processedValue;
              }
              return {
                ...entry,
                isValid: validFrom && entry.validTo,
                validFrom,
                validFromMessage,
                currentData: {
                  ...entry.currentData,
                  from: finalValue,
                },
              };
            }
            return entry;
          });
          return { ...list, confs: updatedConfs };
        }
        return list;
      }),
    );
    dispatch(changedCurrentPlan(reduceListsOfConfs(lists, 'currentData')));
  };

  const validateToData = (index, value, processedValue, list) => {
    if (index === list.length - 1 && value !== '0000') {
      return {
        validTo: false,
        validToMessage: SectorValidationMessages.TO_VALUE_END_ERROR,
      };
    }
    if (index !== list.length - 1 && processedValue <= list[index].localData.from) {
      return {
        validTo: false,
        validToMessage: SectorValidationMessages.TO_SMALLER_FROM_ERROR,
      };
    }
    if (index !== list.length - 1 && index !== list.length - 2 && processedValue >= list[index + 1].localData.to) {
      return {
        validTo: false,
        validToMessage: SectorValidationMessages.TO_VALUE_ERROR,
      };
    }
    return { validTo: true, validToMessage: '' };
  };

  const changeTo = (listId, entryId, value) => {
    setLists(prevLists =>
      prevLists.map((list) => {
        if (list.id === listId) {
          const updatedConfs = list.confs.map((entry) => {
            if (entry.id === entryId) {
              let { validTime: validTo, validTimeMessage: validToMessage } = validateTime(value);
              let finalValue = value;
              if (validTo) {
                const index = list.confs.findIndex(e => e.id === entryId);
                const processedValue = formatedToTimeStamp(value, list.startTime, value.includes(':'));
                ({ validTo, validToMessage } = validateToData(index, value, processedValue, list.confs));
                if (validTo) finalValue = processedValue;
              }
              return {
                ...entry,
                isValid: entry.validFrom && validTo,
                validTo,
                validToMessage,
                currentData: { ...entry.currentData, to: finalValue },
              };
            }
            return entry;
          });
          return { ...list, confs: updatedConfs };
        }
        return list;
      }),
    );
    dispatch(changedCurrentPlan(reduceListsOfConfs(lists, 'currentData')));
  };

  const changeConfig = (listId, entryId, value) => {
    setLists(prevLists =>
      prevLists.map((list) => {
        if (list.id === listId) {
          const updatedConfs = list.confs.map((entry) => {
            if (entry.id === entryId) {
              return {
                ...entry,
                currentData: { ...entry.currentData, conf_name: value },
              };
            }
            return entry;
          });
          return { ...list, confs: updatedConfs };
        }
        return list;
      }),
    );
    dispatch(changedCurrentPlan(reduceListsOfConfs(lists, 'currentData')));
  };

  const commitPreviousTo = (index, list, value) => {
    const previous = list[index - 1];
    const newList = [...list];
    newList[index - 1] = {
      ...previous,
      validTo: true,
      validToMessage: '',
      localData: { ...previous.localData, to: value },
      currentData: { ...previous.currentData, to: value },
    };
    return newList;
  };

  const commitNextFrom = (index, list, value) => {
    const next = list[index + 1];
    const newList = [...list];
    newList[index + 1] = {
      ...next,
      validFrom: true,
      validFromMessage: '',
      localData: { ...next.localData, from: value },
      currentData: { ...next.currentData, from: value },
    };
    return newList;
  };

  const mergeEntries = (index, list) => {
    let newList = [...list];
    const mergePrevious = index !== 0 && list[index].localData.conf_name === list[index - 1].localData.conf_name;
    const mergeNext =
      index !== list.length - 1 && list[index].localData.conf_name === list[index + 1].localData.conf_name;
    if (mergePrevious && mergeNext) {
      newList = commitPreviousTo(index, newList, list[index + 1].localData.to);
      newList.splice(index, 2);
      newList[index - 1].isClicked = true;
      return {
        index: index - 1,
        list: newList,
        mergePrevious,
        mergeNext,
      };
    }
    if (mergePrevious) {
      newList = commitPreviousTo(index, newList, list[index].localData.to);
      newList.splice(index, 1);
      if (index === newList.length) {
        newList[index - 1].isClicked = true;
      } else {
        newList[index].isClicked = true;
      }
      return {
        index: index - 1,
        list: newList,
        mergePrevious,
        mergeNext,
      };
    }
    if (mergeNext) {
      newList = commitNextFrom(index, newList, list[index].localData.from);
      newList.splice(index, 1);
      newList[index].isClicked = true;
      return {
        index,
        list: newList,
        mergePrevious,
        mergeNext,
      };
    }
    return {
      index,
      list: newList,
      mergePrevious,
      mergeNext,
    };
  };

  const deleteEntry = (listId, entryId) => {
    setLists((prevLists) => {
      const listOfEntry = prevLists.find(list => list.id === listId);
      if (listOfEntry.confs.length <= 1) {
        return prevLists;
      }
      const index = listOfEntry.confs.findIndex(entry => entry.id === entryId);
      let listOfEntryConfs = [...listOfEntry.confs];
      const element = listOfEntryConfs[index];
      if (index === 0) {
        listOfEntryConfs = commitNextFrom(index, listOfEntryConfs, element.localData.from);
        listOfEntryConfs.splice(index, 1);
      } else if (
        index !== listOfEntryConfs.length - 1 &&
        listOfEntryConfs[index - 1].localData.conf_name === listOfEntryConfs[index + 1].localData.conf_name
      ) {
        listOfEntryConfs = commitPreviousTo(index, listOfEntryConfs, listOfEntryConfs[index + 1].localData.to);
        listOfEntryConfs.splice(index, 2);
      } else {
        listOfEntryConfs = commitPreviousTo(index, listOfEntryConfs, element.localData.to);
        listOfEntryConfs.splice(index, 1);
      }
      const time = currentTime;
      const listOfEntryConfsCurrent = listOfEntryConfs.map(conf => ({
        ...conf,
        isPastConf: !(conf.localData.from > time || conf.localData.to > time),
        isCurrent: !(conf.localData.from > time || conf.localData.to < time),
        isClicked: !(conf.localData.from > time || conf.localData.to < time),
      }));
      const newLists = prevLists.map(list =>
        (list.id === listId ? { ...list, confs: listOfEntryConfsCurrent } : list),
      );
      const reducedListsToCompare = reduceListsOfConfs(newLists, 'localData');
      setIsEditing(!isEqual(reducedListsToCompare, originalSecto));
      return newLists;
    });
    dispatch(changedCurrentPlan(reduceListsOfConfs(lists, 'currentData')));
  };

  const calcInsertIndexChanges = (id, list) => {
    if (id === -1) {
      return {
        index: 0,
        indexToChange: 0,
        indexToInsert: 0,
        elementToChange: list[0],
      };
    }
    const index = list.findIndex(entry => entry.id === id);
    if (index === list.length - 1) {
      return {
        index,
        indexToChange: index,
        indexToInsert: index + 1,
        elementToChange: list[index],
      };
    }
    return {
      index,
      indexToChange: index + 1,
      indexToInsert: index + 1,
      elementToChange: list[index + 1],
    };
  };

  const calcInsertNewValues = (id, index, list, elementToChange, midnightOfDay) => {
    if (id === -1) {
      return {
        newFrom: midnightOfDay + MINIMUM_INSERT_TIME_MILLISECONDS,
        newTo: elementToChange.localData.to,
        newEntryFrom: midnightOfDay,
        newEntryTo: midnightOfDay + MINIMUM_INSERT_TIME_MILLISECONDS,
      };
    }
    if (index === list.length - 1) {
      return {
        newFrom: elementToChange.localData.from,
        newTo: midnightOfDay - MINIMUM_INSERT_TIME_MILLISECONDS + DAY_IN_MILLISECONDS,
        newEntryFrom: midnightOfDay - MINIMUM_INSERT_TIME_MILLISECONDS + DAY_IN_MILLISECONDS,
        newEntryTo: midnightOfDay + DAY_IN_MILLISECONDS,
      };
    }
    return {
      newFrom: elementToChange.localData.from + MINIMUM_INSERT_TIME_MILLISECONDS,
      newTo: elementToChange.localData.to,
      newEntryFrom: elementToChange.localData.from,
      newEntryTo: elementToChange.localData.from + MINIMUM_INSERT_TIME_MILLISECONDS,
    };
  };

  const insertConfig = (listId, entryId) => {
    setLists((prevLists) => {
      const listOfEntry = prevLists.find(list => list.id === listId);
      const listOfEntryConfs = [...listOfEntry.confs];
      const { index, indexToChange, indexToInsert, elementToChange } = calcInsertIndexChanges(
        entryId,
        listOfEntryConfs,
      );
      const fromToValidate = elementToChange.localData.from;
      let toToValidate = elementToChange.localData.to;
      if (index >= listOfEntryConfs.length - 2) {
        toToValidate = elementToChange.localData.to + DAY_IN_MILLISECONDS;
      }
      if (toToValidate - MINIMUM_INSERT_TIME_MILLISECONDS <= fromToValidate) {
        dispatch(sectorEditError(SectorPlanEditErrorMessages.UNABLE_TO_INSERT_NEW_ENTRY));
        setTimeout(() => {
          dispatch(clearSectorEditError());
        }, TimerIntervals.SECTOR_EDIT_ERROR);
        return prevLists;
      }
      const { newFrom, newTo, newEntryFrom, newEntryTo } = calcInsertNewValues(
        entryId,
        index,
        listOfEntryConfs,
        elementToChange,
        listOfEntry.startTime,
      );
      listOfEntryConfs[indexToChange] = {
        ...elementToChange,
        validFrom: true,
        validFromMessage: '',
        validTo: true,
        validToMessage: '',
        localData: { ...elementToChange.localData, from: newFrom, to: newTo },
        currentData: {
          ...elementToChange.currentData,
          from: newFrom,
          to: newTo,
        },
      };
      const time = currentTime;
      const newConfig = {
        id: `${index}_${newEntryFrom}`,
        isValid: true,
        validFrom: true,
        validFromMessage: '',
        validTo: true,
        validToMessage: '',
        isEditing: true,
        isHovering: false,
        isCurrent: false,
        isPastConf: !(newEntryFrom > time || newEntryTo > time),
        isClicked: false,
        currentData: {
          conf_name: possibleConfs[0],
          from: newEntryFrom,
          to: newEntryTo,
        },
        localData: {
          conf_name: possibleConfs[0],
          from: newEntryFrom,
          to: newEntryTo,
        },
      };
      listOfEntryConfs.splice(indexToInsert, 0, newConfig);
      const listOfEntryConfsCurrent = listOfEntryConfs.map(conf => ({
        ...conf,
        isCurrent: !(conf.localData.from > time || conf.localData.to < time),
      }));
      const newLists = prevLists.map(list =>
        (list.id === listId ? { ...list, confs: listOfEntryConfsCurrent } : list),
      );
      setIsEditing(true);
      return newLists;
    });
    dispatch(changedCurrentPlan(reduceListsOfConfs(lists, 'currentData')));
  };

  const handleSyncToNM = (deltaDay) => {
    const profileNames = getProfileNames(sectoPlan);

    dispatch(updateNMPlan({ profileName: selectedCTA.name, index: deltaDay }))
      .then(() => {
        setSelectedPlan({ day: undefined, delta: undefined });
        alertContext.setErrorMessageSnackBar(SnackBarMessages.NM_UPDATE_SUCCESS);
        alertContext.setSnackBarSeverity(AlertTypes.SUCCESS);
        alertContext.setShowSnackBar(true);
        getSectoPlanUpdated(profileNames);
      })
      .catch(() => {
        alertContext.setErrorMessageSnackBar(SnackBarMessages.NM_UPDATE_ERROR);
        alertContext.setSnackBarSeverity(AlertTypes.ERROR);
        alertContext.setShowSnackBar(true);
      });
  };

  const confirmEdit = (listId, entryId) => {
    setLists((prevLists) => {
      const listOfEntry = prevLists.find(list => list.id === listId);
      let index = listOfEntry.confs.findIndex(entry => entry.id === entryId);
      const element = listOfEntry.confs[index];
      const changedFrom = element.currentData.from !== element.localData.from;
      const changedTo = element.currentData.to !== element.localData.to;
      const changedConf = element.currentData.conf_name !== element.localData.conf_name;
      let listOfEntryConfs = [...listOfEntry.confs];
      listOfEntryConfs[index] = {
        ...element,
        isEditing: false,
        localData: { ...element.currentData },
      };
      if (!changedFrom && !changedTo && !changedConf) {
        return prevLists.map(list => (list.id === listId ? { ...list, confs: listOfEntryConfs } : list));
      }
      let mergePrevious = false;
      let mergeNext = false;
      if (changedConf) {
        ({ index, list: listOfEntryConfs, mergePrevious, mergeNext } = mergeEntries(index, listOfEntryConfs));
      }
      if (!mergePrevious && changedFrom && index !== 0) {
        listOfEntryConfs = commitPreviousTo(index, listOfEntryConfs, element.currentData.from);
      }
      if (!mergeNext && changedTo && index !== listOfEntryConfs.length - 1) {
        listOfEntryConfs = commitNextFrom(index, listOfEntryConfs, element.currentData.to);
      }
      const time = currentTime;
      const listOfEntryConfsCurrent = listOfEntryConfs.map(conf => ({
        ...conf,
        isCurrent: !(conf.localData.from > time || conf.localData.to < time),
      }));
      const newLists = prevLists.map(list =>
        (list.id === listId ? { ...list, confs: listOfEntryConfsCurrent } : list),
      );
      const reducedListsToCompare = reduceListsOfConfs(newLists, 'localData');
      setIsEditing(!isEqual(reducedListsToCompare, originalSecto));
      return newLists;
    });
    dispatch(changedCurrentPlan(reduceListsOfConfs(lists, 'currentData')));
  };

  const renderListEntry = (listId, entry) => (
    <Fragment key={entry.id}>
      <SectoConfigComponent
        key={entry.id}
        listId={listId}
        entryId={entry.id}
        entry={entry}
        possibleConfs={possibleConfs || []}
        changeEditStatus={changeEditStatus}
        changeClickStatus={changeClickStatus}
        changeFrom={changeFrom}
        changeTo={changeTo}
        changeConfig={changeConfig}
        deleteEntry={deleteEntry}
        confirmEdit={confirmEdit}
      />
      {!entry.isPastConf && (
        <InsertNewConfig key={`new${entry.id}`} listId={listId} entryId={entry.id} insertConfig={insertConfig} />
      )}
    </Fragment>
  );

  const renderNMToolbar = (day, delta, loadingState) => {
    if (!loadingState) {
      setSelectedPlan(prevSelectedPlan =>
        (prevSelectedPlan === day ? { day: undefined, delta: undefined } : { day, delta }),
      );
    }
  };

  const renderLists = listToRender =>
    listToRender.map((list, index) => (
      <Fragment key={list.id}>
        <div className="day-separator">
          <p>{timestampToDate(list.startTime)}</p>
          {(getTokenData() === SourceNames.NMOPS ||
            getTokenData() === SourceNames.NMPREOPS) && (
            <div className="nm-handle-container">
              {isLoadingNm &&
                selectedPlan.day === list.startTime &&
                selectedPlan.delta === index && (
                  <div className="nm-progress-circle">
                    <p>Retrieving from NM</p>
                    <CircularProgress size={14} />
                  </div>
              )}

              <button
                className="button-plan-menu"
                type="button"
                onClick={() =>
                  renderNMToolbar(list.startTime, index, isLoadingNm)
                }
              >
                <icons.MoreVert />
              </button>

              {!isLoadingNm &&
                selectedPlan.day === list.startTime &&
                selectedPlan.delta === index && (
                  <div className="menu-plan-container">
                    <button
                      className="button-nm-sync"
                      type="button"
                      onClick={() => {
                        handleSyncToNM(index);
                      }}
                    >
                      <icons.Refresh />
                      Reload NM Plan
                    </button>
                  </div>
              )}
            </div>
          )}
        </div>
        {list.confs.map(conf => renderListEntry(list.id, conf))}
      </Fragment>
    ));

  useInterval(() => {
    const from = currentTime - SectorRequestTimeWindow.DELTA_FROM;
    const to = currentTime + SectorRequestTimeWindow.DELTA_TO;
    getHotspots(from, to);
  }, TimerIntervals.TIMELINE);

  useEffect(() => {
    if (lists.length > 0) {
      dispatch(configSelected(activeConf));
      dispatch(fetchPossibleConfs(selectedCTA.name));
    }

    return () => {
      dispatch(clearConfigSelected());
    };
  }, []);

  useEffect(() => {
    const updateActiveConfiguration = getActiveConf(currentTime, lists);
    setActiveConf(updateActiveConfiguration);

    const clickedConfiguration = getConfFromListsOfConfs(lists, conf => conf.isClicked);

    if (clickedConfiguration && selectedConfig) {
      const {
        localData: { conf_name: clickedConfName, from: clickedFrom, to: clickedTo },
      } = clickedConfiguration;

      const {
        localData: { conf_name: selectedConfName, from: selectedFrom, to: selectedTo },
      } = selectedConfig;

      const isDifferentConfig =
        clickedConfName !== selectedConfName || clickedFrom !== selectedFrom || clickedTo !== selectedTo;

      if (isDifferentConfig) {
        dispatch(configSelected(localSelectedData(clickedConfiguration)));
      }
    }
  }, [lists, selectedConfig]);

  useEffect(() => {
    const data = getActiveConf(currentTime, lists);

    if (!isEditing && JSON.stringify(data) !== JSON.stringify(activeConf)) {
      const initial = initialState(currentTime, selectedCTA, selectedConfig);
      setLists(initial.lists);
      setOriginalSecto(initial.originalSecto);
    }
  }, [currentTime, isEditing]);

  useEffect(() => {
    const initial = initialState(currentTime, selectedCTA, selectedConfig);

    setLists(initial.lists);
    setOriginalSecto(initial.originalSecto);
  }, [selectedCTA.confs]);

  useEffect(() => {
    dispatch(fetchPossibleConfs(selectedCTA.name));
  }, [selectedCTA.name]);

  return (
    <div id="config-list-container">
      <div className="config-header-container">
        <h1 className="config-header-title">SECTORISATION PLAN</h1>
        <div className="config-header-bottom">
          {sectoPlan.length > 1 && <MultiCTAHandle />}
          {isEditing && (
            <div id="config-list-cancel-save">
              <PrimaryButton
                className="button save"
                id="save-changes-btn"
                onClick={() => lists.forEach(list => saveChanges(list))}
                type="button"
                disabled={isSavingConfigs}
              >
                {isSavingConfigs || hotspotsLoading ? <i className="fas fa-spinner fa-spin" /> : <div>Save changes</div>}
              </PrimaryButton>
              <button className="button cancel" onClick={cancelChanges} type="button">
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
      <div id="configs-list">{renderLists(lists)}</div>
    </div>
  );
};

const SectoConfigListWithContext = props => (
  <AlertContext.Consumer>
    {alertContext => <SectoConfigListComponent {...props} alertContext={alertContext} />}
  </AlertContext.Consumer>
);

export default SectoConfigListWithContext;
