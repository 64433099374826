import { HotspotsTypes } from '../../constants';

export const selectHotspots = state => state.hotspots;

export const selectTimelineLoading = state => !!selectHotspots(state).requestId;

export const selectTimelineData = state => selectHotspots(state).timelineData;

export const selectTimelineCurrentHotspots = state => selectHotspots(state).timelineHotspots;

export const selectTimelineCurrentKpis = state => selectHotspots(state).timelineKpis;

export const selectNfaHotspots = state => selectTimelineCurrentHotspots(state) &&
  selectTimelineCurrentHotspots(state).filter(hotspot => hotspot.type === HotspotsTypes.NFA);

export const selectTimelineCurrentTrafficLoads = state => selectHotspots(state).timelineTrafficLoads;

export const selectSelectedHotspot = state => selectHotspots(state).selectedHotspot;

export const selectSelectedHotspotStartingTime = state => (selectSelectedHotspot(state) ? selectSelectedHotspot(state).starting_time : undefined);

export const selectSelectedTimelineHotspotTrafficLoad = state => selectHotspots(state).selectedTimelineHotspotTrafficLoad;

export const selectIsDeclaringDCBHotspot = state => selectHotspots(state).isDeclaringDCBHotspot;

export const selectErrorDeclaringDCBHotspot = state => selectHotspots(state).declareError;

export const selectSelectedHotspotUpdateMessage = state => selectHotspots(state).updateMessage;

export const selectIsLoadingRelationshipHotspots = state => selectHotspots(state).isLoadingRelationshipHotspots;

export const selectRelationshipHotspots = state => selectHotspots(state).relationshipHotspots;

export const selectRelationshipKpis = state => selectHotspots(state).relationshipKpis;
