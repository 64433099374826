import { createSelector } from '@reduxjs/toolkit';

const selectWhatif = state => state.whatif;

export const selectSelectedWhatif = createSelector(
  selectWhatif,
  whatif => whatif?.selectedWhatif,
);

export const selectActiveWhatif = createSelector(
  selectWhatif,
  whatif => whatif?.activeWhatif,
);

export const selectSessionId = createSelector(
  selectActiveWhatif,
  activeWhatif => activeWhatif?.sessionId,
);

export const selectRequestingWhatIfs = createSelector(
  selectWhatif,
  whatif => whatif.requestingWhatIf,
);

export const selectActiveWhatifMeasures = createSelector(
  selectWhatif,
  whatif => whatif.activeWhatifMeasures || [],
);

export const selectWhatifsOpen = createSelector(
  selectWhatif,
  whatif => whatif.whatifsOpen,
);

export const selectIsProposingStam = createSelector(
  selectWhatif,
  whatif => whatif.isProposingStam,
);

export const selectSimulationsList = createSelector(
  selectWhatif,
  whatif => whatif.simulationsList,
);

export const selectRequestingComputeTrajectory = createSelector(selectWhatif, whatif => whatif.requestingComputeTrajectory);

export const selectComputeTrajectory = createSelector(
  selectWhatif,
  whatif => whatif.computeTrajectory,
);

export const selectComputeTrajectoryFails = createSelector(selectWhatif, whatif => whatif.computeTrajectoryFails);
