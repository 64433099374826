import { createSelector } from '@reduxjs/toolkit';

const selectFeatureFlags = state => state.featureFlags;

export const selectWeatherFlag = createSelector(
  selectFeatureFlags,
  featureFlags => featureFlags.WEATHER,
);

export const selectShowCompHotspots = createSelector(
  selectFeatureFlags,
  featureFlags => featureFlags.SHOW_COMPLEXITY_HS,
);
