import { useEffect, useRef } from 'react';

/* eslint-disable import/prefer-default-export */
export const clearIntervalIfExists = (interval) => {
  if (interval) {
    clearInterval(interval);
  }
};

// Making setInterval Declarative with React Hooks
// link: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// see also: https://stackoverflow.com/a/59274004
export const useInterval = (callback, delay) => {
  const intervalRef = useRef();
  const callbackRef = useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setInterval ticks again, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // interval will be resetted.

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval:

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = setInterval(() => callbackRef.current(), delay);

      // Clear interval if the components is unmounted or the delay changes:
      return () => clearInterval(intervalRef.current);
    }
  }, [delay]);

  // Returns a ref to the interval ID in case you want to clear it manually:
  return intervalRef;
};
